// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-gatsbystorefront-gatsby-theme-storefront-shopify-pages-404-jsx": () => import("./../../../src/@gatsbystorefront/gatsby-theme-storefront-shopify/pages/404.jsx" /* webpackChunkName: "component---src-gatsbystorefront-gatsby-theme-storefront-shopify-pages-404-jsx" */),
  "component---src-gatsbystorefront-gatsby-theme-storefront-shopify-templates-cart-index-jsx": () => import("./../../../src/@gatsbystorefront/gatsby-theme-storefront-shopify/templates/cart/index.jsx" /* webpackChunkName: "component---src-gatsbystorefront-gatsby-theme-storefront-shopify-templates-cart-index-jsx" */),
  "component---src-gatsbystorefront-gatsby-theme-storefront-shopify-templates-main-index-jsx": () => import("./../../../src/@gatsbystorefront/gatsby-theme-storefront-shopify/templates/main/index.jsx" /* webpackChunkName: "component---src-gatsbystorefront-gatsby-theme-storefront-shopify-templates-main-index-jsx" */),
  "component---src-pages-policies-jsx": () => import("./../../../src/pages/policies.jsx" /* webpackChunkName: "component---src-pages-policies-jsx" */)
}

